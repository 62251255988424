import styled from "styled-components";

import media from "constants/media";

import IconArrowRight from "components/ui/icons/IconArrowRight";

export const ArrowIcon = styled(IconArrowRight)`
    position: absolute;
    right: 20px;
    bottom: 25px;

    transition: all var(--animation-duration-default) ease;
`;

export const Content = styled.div`
    position: relative;

    padding: 20px;

    background-color: var(--color-white);
`;

export const ImageContainer = styled.div`
    position: relative;

    width: 100%;

    background-color: var(--color-violet-90);
    aspect-ratio: 19 / 21;

    ${media.mdUp} {
        aspect-ratio: 5 / 4;
    }
`;

export const Title = styled.h4`
    margin: 0;
    padding: 0;
    overflow: hidden;

    color: var(--color-black);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all var(--animation-duration-default) ease;

    ${media.mdUp} {
        font-size: var(--font-size-h5);
        line-height: var(--line-height-h5);
    }
`;

export const Date = styled.p`
    margin: 0;
    overflow: hidden;

    color: var(--color-black);
    font-weight: normal;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Location = styled.p`
    width: calc(100% - 35px);
    margin: 0;
    overflow: hidden;

    color: var(--color-gray-65);
    font-weight: normal;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Container = styled.article`
    display: block;
    width: 100%;
    overflow: hidden;

    border-radius: var(--border-radius-default);
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.15);

    transition: all var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        :hover,
        :focus {
            box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.15);
            transform: scale(1.02);

            ${Title} {
                color: var(--color-primary);
            }

            ${ArrowIcon} {
                transform: translateX(5px);
            }
        }
    }

    ${media.mdUp} {
        box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.15);

        @media (any-hover: hover) {
            :hover,
            :focus {
                box-shadow: 0 35px 28px -10px rgba(0, 0, 0, 0.15);
            }
        }
    }
`;
