import PropTypes from "prop-types";

function IconArrowRight({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            clipRule="evenodd"
            fillRule="evenodd"
            {...rest}
        >
            <path d="m19 13 18.9 18.9-18.9 19.1h8.1l18.9-19.1-18.9-18.9z" />
        </svg>
    );
}

IconArrowRight.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconArrowRight.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconArrowRight;
