export function getEventDateText(from, till) {
    const fromDate = new Date(from);
    const tillDate = new Date(till);
    const now = new Date();
    const isOneDayEvent = +fromDate === +tillDate;
    let date = fromDate;
    let prefix = "";

    if (!isOneDayEvent) {
        if (fromDate > now) {
            prefix += "Vanaf ";
        }

        if (fromDate < now && tillDate > now) {
            prefix += "Tot en met ";
            date = tillDate;
        }
    }

    return `${prefix}${date.toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "long"
    })}`;
}
