import PropTypes from "prop-types";

const EventSliderItem = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        slug: PropTypes.string.isRequired,
        address: PropTypes.shape({
            provinceCode: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired
        }).isRequired
    })
};

export const EventSliderItemApiPropTypes = PropTypes.shape({
    ...EventSliderItem,
    from: PropTypes.string.isRequired,
    till: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
});

export default {
    ...EventSliderItem,
    date: PropTypes.string.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    })
};
