import styled from "styled-components";

import media from "constants/media";

import { skeleton } from "components/ui/skeleton/Skeleton";

export const ImageSkeleton = styled.div`
    position: relative;
    z-index: 1;

    width: 100%;
    aspect-ratio: 210 / 135;
    overflow: hidden;

    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;

    ${media.mdUp} {
        aspect-ratio: 190 / 121;
    }

    ${skeleton}
`;

export const TitleSkeleton = styled.div`
    width: 100%;
    min-height: 20px;

    border-radius: var(--border-radius-small);

    ${media.mdUp} {
        min-height: 30px;
    }

    ${skeleton}
`;
