import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { useInView } from "react-intersection-observer";

import { EventSliderItemApiPropTypes } from "types/EventSliderItem.propTypes";
import { getEventDateText } from "helpers/event";
import { GetLatestEvents } from "lib/api/cc/queries/events.gql";

import Slider from "components/ui/slider/Slider";

import SkeletonCard from "./card/skeleton/SkeletonCard";
import Card from "./card/Card";

import { EventsSliderStyled, Title, NoResults } from "./EventsSlider.styled";

function renderCards(events, showMerchant) {
    if (!events || events.length <= 0) {
        return [];
    }

    return events.map(({ id, title, images, from, till, merchant }) => (
        <Card
            key={id}
            id={id}
            title={title}
            image={
                images?.[0] || {
                    url: "/images/placeholder.jpg",
                    alt: "placeholder"
                }
            }
            date={getEventDateText(from, till)}
            merchant={merchant}
            showMerchant={showMerchant}
        />
    ));
}

const generateSkeletonCards = limit =>
    Array(limit)
        .fill()
        .map((item, index) => (
            <SkeletonCard key={`EventSlider-skeleton-${index}`} />
        ));

function EventsSlider({
    title,
    events,
    showMerchant,
    filter,
    limit,
    slidesToShow,
    pagination,
    className
}) {
    const [eventCount, setEventCount] = useState(events?.length ?? 0);
    const [cards, setCards] = useState(generateSkeletonCards(limit));
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });
    const { loading } = useQuery(GetLatestEvents, {
        variables: {
            filter,
            pageSize: limit,
            sort: "PROMOTED"
        },
        onCompleted: ({ events }) => {
            setEventCount(events?.edges?.length ?? 0);

            if (events?.edges?.length > 0) {
                setCards(renderCards(events?.edges, showMerchant));
            }
        },
        skip: events?.length > 0 || !inView || !process.env.featureFlags.cmsData
    });

    useEffect(() => {
        if (events.length > 0) {
            setCards(renderCards(events, showMerchant));
        }
    }, [events, showMerchant]);

    return (
        <EventsSliderStyled ref={ref} aria-busy={loading} className={className}>
            <Title>{title}</Title>
            {!loading && eventCount === 0 && (
                <NoResults>Er zijn geen activiteiten gevonden.</NoResults>
            )}
            {inView && !loading && eventCount > 0 && (
                <Slider
                    navigationMobile={false}
                    items={cards}
                    slidesToShow={slidesToShow}
                    overflowVisible
                    pagination={pagination}
                />
            )}
        </EventsSliderStyled>
    );
}

EventsSlider.propTypes = {
    title: PropTypes.string,
    events: PropTypes.arrayOf(EventSliderItemApiPropTypes),
    showMerchant: PropTypes.bool,
    filter: PropTypes.object,
    limit: PropTypes.number,
    slidesToShow: PropTypes.number,
    pagination: PropTypes.bool,
    className: PropTypes.string
};

EventsSlider.defaultProps = {
    title: "Aankomende activiteiten",
    events: [],
    showMerchant: true,
    filter: {
        promoted: true
    },
    limit: 6,
    slidesToShow: 2.7,
    pagination: true,
    className: ""
};

export default EventsSlider;
