import styled from "styled-components";

import media from "constants/media";

import IconArrowRight from "components/ui/icons/IconArrowRight";

export const Anchor = styled.a`
    display: block;
    width: 100%;
`;

export const Title = styled.h3`
    z-index: 2;

    display: flex;
    gap: 0 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 20px;
    overflow: hidden;

    color: var(--color-black);
    font-weight: var(--font-weight-bold);
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;

    background-color: var(--color-white);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);

    transition: all var(--animation-duration-default) ease;

    ${media.lgUp} {
        padding: 20px 30px;

        font-size: var(--font-size-body);
        line-height: var(--line-height-body);
    }
`;

export const Ellipsis = styled.span`
    display: block;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Arrow = styled(IconArrowRight)`
    flex: 0 0 auto;

    transition: all var(--animation-duration-default) ease;
`;

export const Container = styled.article`
    position: relative;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    border-radius: var(--border-radius-default);
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.15);

    cursor: pointer;

    transition: all var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        :hover,
        :focus {
            box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.15);
            transform: scale(1.02);

            ${Arrow} {
                transform: translateX(5px);

                fill: var(--color-primary);
            }
        }
    }

    ${media.xlUp} {
        max-width: 380px;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    z-index: 1;

    width: 100%;
    aspect-ratio: 95 / 104;
    overflow: hidden;

    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;

    ${media.mdUp} {
        aspect-ratio: 190 / 121;
    }
`;
