import PropTypes from "prop-types";
import Link from "next/link";

import { EventSliderItemApiPropTypes } from "types/EventSliderItem.propTypes";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import EventsSlider from "components/shared/eventsSlider/EventsSlider";

import Finder from "./finder/Finder";

import {
    Wrapper,
    ContentWrapper,
    Intro,
    Title,
    Description,
    StyledButton,
    EventsWrapper,
    MaskWrapper,
    EventsContainer
} from "./MerchantsAndEvents.styled";

function MerchantsAndEvents({
    title,
    description,
    link,
    eventsTitle,
    events,
    showFinder,
    isChristmas
}) {
    const { mobileView, desktopView } = useMediaQueryContext();
    const slidesToShow = desktopView ? 2 : 1.5;

    return (
        <Wrapper>
            {showFinder && (
                <ContentWrapper>
                    <Intro>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                        <Link href={link?.url} passHref prefetch={false}>
                            <StyledButton
                                element="a"
                                variant={mobileView ? "outline" : "primary"}
                            >
                                {link?.label}
                            </StyledButton>
                        </Link>
                        <Finder />
                    </Intro>
                </ContentWrapper>
            )}
            <MaskWrapper isChristmas={isChristmas}>
                <EventsWrapper isChristmas={isChristmas}>
                    <EventsContainer>
                        <EventsSlider
                            title={eventsTitle}
                            events={events}
                            limit={9}
                            pagination={events.length <= 9}
                            slidesToShow={slidesToShow}
                        />
                    </EventsContainer>
                </EventsWrapper>
            </MaskWrapper>
        </Wrapper>
    );
}

MerchantsAndEvents.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    eventsTitle: PropTypes.string,
    events: PropTypes.arrayOf(EventSliderItemApiPropTypes),
    showFinder: PropTypes.bool,
    isChristmas: PropTypes.bool
};

MerchantsAndEvents.defaultProps = {
    title: "Culturele instellingen",
    description:
        "Onze Kunst en Cultuur Cadeaukaart is te besteden bij verschillende culturele instellingen in Nederland en België. Zo is er altijd een bij jou in de buurt.",
    link: {
        url: "/waar-te-besteden/tickets-aan-de-kassa/",
        label: "alle locaties bekijken"
    },
    eventsTitle: "Aankomende activiteiten",
    events: [],
    showFinder: true,
    isChristmas: false
};

export default MerchantsAndEvents;
