import styled from "styled-components";

import media from "constants/media";

export const EventsSliderStyled = styled.div`
    width: 100%;
`;

export const Title = styled.h3`
    margin: 0 0 15px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);

    ${media.mdUp} {
        margin: 0 0 25px;
    }
`;

export const NoResults = styled.p``;
