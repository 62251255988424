import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import {
    Anchor,
    Container,
    ImageWrapper,
    Title,
    Ellipsis,
    Arrow
} from "./Theme.styled";

function Theme({ title, slug, image }) {
    return (
        <Link
            href={`/waar-te-besteden/tickets-aan-de-kassa/?cat=${slug}`}
            passHref
            prefetch={false}
        >
            <Anchor>
                <Container>
                    <Title>
                        <Ellipsis>{title}</Ellipsis>
                        <Arrow size={24} fill="var(--color-secondary)" />
                    </Title>
                    <ImageWrapper>
                        <Image
                            src={image.url}
                            alt={image.alt}
                            quality={75}
                            layout="fill"
                            objectFit="cover"
                        />
                    </ImageWrapper>
                </Container>
            </Anchor>
        </Link>
    );
}

Theme.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
};

export default Theme;
