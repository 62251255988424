import PropTypes from "prop-types";
import Image from "next/image";
import Link from "next/link";

import EventSliderItemPropTypes from "types/EventSliderItem.propTypes";
import { generateSlug } from "helpers/utils";

import {
    Container,
    Content,
    ImageContainer,
    Title,
    Date,
    Location,
    ArrowIcon
} from "./Card.styled";

function getLocationText(title, city) {
    const parts = [];

    if (title) {
        parts.push(title);
    }

    if (city) {
        parts.push(city);
    }

    return parts.join(", ");
}

function getMerchantUrl(address, slug) {
    if (!address || !slug) {
        return "/waar-te-besteden/tickets-aan-de-kassa/";
    }

    const province = generateSlug(address?.provinceCode ?? "");
    const city = generateSlug(address?.city ?? "");
    return `/waar-te-besteden/tickets-aan-de-kassa/${province}/${city}/${slug}/`;
}

function Card({ title, image, date, merchant, showMerchant }) {
    const { address, slug } = merchant || {};
    const href = getMerchantUrl(address, slug);
    const location = getLocationText(merchant?.title, address?.city);

    return (
        <Container>
            <Link href={href} passHref prefetch={false}>
                <a>
                    <ImageContainer>
                        {image && (
                            <Image
                                src={image.url}
                                alt={image.alt}
                                layout="fill"
                                objectFit="cover"
                            />
                        )}
                    </ImageContainer>
                    <Content>
                        <Title aria-label={`Evenement: ${title}`}>
                            {title}
                        </Title>
                        <Date>{date}</Date>
                        {showMerchant && location && (
                            <Location aria-label={`Locatie: ${location}`}>
                                {location}
                            </Location>
                        )}
                        <ArrowIcon
                            size={24}
                            fill="var(--color-secondary)"
                            aria-hidden="true"
                        />
                    </Content>
                </a>
            </Link>
        </Container>
    );
}

Card.propTypes = {
    ...EventSliderItemPropTypes,
    showMerchant: PropTypes.bool
};

Card.defaultProps = {
    showMerchant: true
};

export default Card;
