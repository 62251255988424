import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { useInView } from "react-intersection-observer";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { GetThemes } from "lib/api/cc/queries/themes.gql";

import Container from "components/ui/container/Container";
import Slider from "components/ui/slider/Slider";

import SkeletonTheme from "./theme/skeleton/SkeletonTheme";
import Theme from "./theme/Theme";

import {
    Wrapper,
    ThemeSliderStyled,
    Content,
    Title,
    ImageWrapper
} from "./ThemeSlider.styled";

function renderThemes(themes) {
    if (!themes || themes.length <= 0) {
        return [];
    }

    return themes.map(({ id, title, hero }) => (
        <Theme
            key={id}
            slug={encodeURIComponent(title)}
            title={title}
            image={
                hero?.image || {
                    url: "/images/placeholder.jpg",
                    alt: title
                }
            }
        />
    ));
}

const generateSkeletonCards = limit =>
    Array(limit)
        .fill()
        .map((item, index) => (
            <SkeletonTheme key={`ThemeSlider-skeleton-${index}`} />
        ));

function ThemeSlider({
    title,
    image,
    themes,
    filter,
    limit,
    slidesToShow,
    navigationMobile,
    navigationDesktop,
    showImage,
    invert,
    className
}) {
    const [items, setItems] = useState(generateSkeletonCards(limit));
    const { mobileView } = useMediaQueryContext();
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });

    const { loading } = useQuery(GetThemes, {
        variables: {
            filter,
            pageSize: limit
            // @TODO adding sort breaks API, enable when working again
            // sort: "PROMOTED"
        },
        onCompleted: ({ themes }) => {
            // eslint-disable-next-line react/prop-types
            if (themes?.edges?.length > 0) {
                setItems(renderThemes(themes?.edges));
            }
        },
        skip: themes?.length > 0 || !inView || !process.env.featureFlags.cmsData
    });

    useEffect(() => {
        if (themes?.length > 0) {
            setItems(renderThemes(themes));
        }
    }, [themes]);

    return (
        <Wrapper showImage={showImage} invert={invert}>
            {showImage && (
                <ImageWrapper>
                    <Image
                        src={image?.url}
                        alt={image?.alt}
                        layout="fill"
                        objectFit="cover"
                        quality={75}
                    />
                </ImageWrapper>
            )}
            <ThemeSliderStyled
                ref={ref}
                aria-busy={loading}
                invert={invert}
                className={className}
            >
                <Container>
                    <Content invert={invert}>
                        <Title invert={invert}>{title}</Title>
                    </Content>
                    <Slider
                        navigationMobile={navigationMobile}
                        navigationDesktop={navigationDesktop}
                        items={items}
                        slidesToShow={mobileView ? 1.3 : slidesToShow}
                        overflowVisible
                        fadeEdges
                    />
                </Container>
            </ThemeSliderStyled>
        </Wrapper>
    );
}

ThemeSlider.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    themes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            hero: PropTypes.shape({
                image: PropTypes.shape({
                    url: PropTypes.string.isRequired,
                    alt: PropTypes.string.isRequired
                })
            })
        })
    ),
    filter: PropTypes.object,
    limit: PropTypes.number,
    slidesToShow: PropTypes.number,
    navigationMobile: PropTypes.bool,
    navigationDesktop: PropTypes.bool,
    showImage: PropTypes.bool,
    invert: PropTypes.bool,
    className: PropTypes.string
};

ThemeSlider.defaultProps = {
    title: "Kunst & Cultuur thema's",
    image: {
        url: "/images/lp-themes-banner-mobile.jpg",
        alt: "Kunst & Cultuur thema's"
    },
    themes: [],
    filter: {
        promoted: true
    },
    limit: 9,
    slidesToShow: 3,
    navigationMobile: false,
    navigationDesktop: false,
    showImage: true,
    invert: false,
    className: ""
};

export default ThemeSlider;
