import styled, { css } from "styled-components";
import media from "constants/media";

import { columns } from "theme/mixins";

import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 50px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        align-items: flex-start;
        margin: 0 0 60px;
        padding: 0;
    }
`;

export const MaskWrapper = styled.section`
    ${media.mdUp} {
        position: relative;

        :before,
        :after {
            position: absolute;

            content: "";
        }

        :before {
            top: 0;
            left: -800px;
            z-index: 1;

            width: 813px;
            height: 638px;

            background: url("/images/shapes/lp-events-bg-mask-desktop.webp") top
                right/831px 660px no-repeat;
        }

        :after {
            left: -620px;
            z-index: 2;

            ${({ isChristmas }) =>
                isChristmas
                    ? css`
                          top: 60px;

                          width: 633px;
                          height: 584px;

                          background: url("/images/shapes/lp-merchants-particles-christmas-desktop.svg")
                              top left/auto no-repeat;
                      `
                    : css`
                          top: 100px;

                          width: 633px;
                          height: 556px;

                          background: url("/images/shapes/lp-merchants-particles-desktop.svg")
                              top left/auto no-repeat;
                      `}
        }
    }
`;

export const ContentWrapper = styled.section`
    display: flex;
    padding: 0 10px;

    ${media.mdUp} {
        position: relative;
        z-index: 3;

        justify-content: flex-end;
        width: calc(
            ((100vw - var(--container-width)) / 2) + 10px + ${columns(5)}
        );
    }

    ${media.lgUp} {
        z-index: unset;

        :before {
            position: absolute;
            top: 0;
            left: -100px;
            z-index: 1;

            width: 50vw;
            height: 638px;

            background: var(--color-white);

            content: "";
        }
    }
`;

export const Intro = styled.div`
    z-index: 2;

    display: flex;
    flex-direction: column;
    padding: 0;

    ${media.mdUp} {
        flex: 0 0 auto;
        width: calc(${columns(5)});
        padding: 70px 0;
    }
`;

export const Title = styled.h2`
    margin: 0 0 10px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);

    ${media.xlUp} {
        margin: 0 -80px 10px 0;
    }
`;

export const Description = styled(ContentRenderer)`
    p {
        margin: 0 0 15px;
    }

    ${media.mdUp} {
        p {
            margin: 0 0 20px;
        }
    }

    ${media.xlUp} {
        max-width: ${columns(4)};
    }
`;

export const StyledButton = styled(Button)`
    order: 1;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;

    ${media.mdUp} {
        order: unset;
        width: unset;
        margin: 0 auto 0 0;
        padding-right: 50px;
        padding-left: 50px;
    }
`;

export const EventsWrapper = styled.div`
    position: relative;

    margin: 0 0 25px;

    ${media.mdUp} {
        width: calc(
            ((100vw - var(--container-width)) / 2) + 10px + ${columns(7)}
        );
        min-height: 660px;
        margin: 0;
        padding: 70px 0 70px calc(${columns(1)} + var(--gutter));

        background: url("/images/shapes/lp-events-bg-desktop.webp") top
            left/818px 660px no-repeat;

        :before,
        :after {
            position: absolute;

            content: "";
        }

        :before {
            left: -620px;

            ${({ isChristmas }) =>
                isChristmas
                    ? css`
                          top: 60px;

                          width: 1440px;
                          height: 584px;

                          background: url("/images/shapes/lp-merchants-particles-christmas-desktop.svg")
                              top left/auto no-repeat;
                      `
                    : css`
                          top: 100px;

                          width: 1440px;
                          height: 556px;

                          background: url("/images/shapes/lp-merchants-particles-desktop.svg")
                              top left/auto no-repeat;
                      `}
        }

        :after {
            top: 14px;
            right: 0;
            z-index: -1;

            width: 50vw;
            height: 638px;

            background: var(--color-secondary);
        }
    }
`;

export const EventsContainer = styled.div`
    padding: 0 10px;

    ${media.mdUp} {
        width: ${columns(6)};
        padding: 0;

        color: var(--color-white);
    }
`;
