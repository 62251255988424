import styled, { css } from "styled-components";

import media from "constants/media";

import { LeftFade, RightFade } from "components/ui/slider/Slider.styled";

export const ImageWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 500px;

    :before,
    :after {
        position: absolute;
        left: -50px;

        width: 427px;
        height: 60px;

        content: "";
    }

    :before {
        top: -35px;
        z-index: 1;

        background: url("/images/shapes/lp-usage-brush-mobile.svg") top
            left/427px 60px no-repeat;
    }

    :after {
        bottom: -15px;

        background: url("/images/shapes/lp-themes-brush-mobile-red.svg") top
            left/427px 60px no-repeat;
    }

    ${media.mobileOnly}
`;

export const Wrapper = styled.section`
    position: relative;

    margin: 0 0 45px;

    background: var(--color-secondary);

    :after {
        position: absolute;
        bottom: -11px;
        left: -50px;
        z-index: 1;

        width: 427px;
        height: 60px;

        background: url("/images/shapes/lp-themes-brush-mobile-red.svg") top
            left/427px 60px no-repeat;

        content: "";
    }

    ${({ showImage }) =>
        !showImage &&
        css`
            :before {
                position: absolute;
                top: -35px;
                left: -50px;
                z-index: 1;

                width: 427px;
                height: 60px;

                background: url("/images/shapes/lp-usage-brush-mobile.svg") top
                    left/427px 60px no-repeat;

                content: "";
            }
        `}

    ${media.mdUp} {
        ${({ invert }) =>
            invert
                ? css`
                        position: relative;

                        margin: 0 0 90px;
                        padding: 80px 0;

                        background: var(--color-secondary);

                        :before,
                        :after {
                            position: absolute;
                            left: calc(50% - 1661px / 2);
                            z-index: -1;

                            width: 1661px;
                            height: 149px;

                            content: "";
                        }

                        :before {
                            top: -26px;
                            left: calc(50% - 1513px / 2);

                            background: url("/images/shapes/where-to-spend-brush-desktop-top.svg")
                                center/1661px 149px no-repeat;
                        }

                        :after {
                            bottom: -26px;

                            background: url("/images/shapes/where-to-spend-brush-desktop-bottom.svg")
                                center/1661px 149px no-repeat;
                    `
                : css`
                      margin: 0;

                      background-color: var(--color-white);

                      :before,
                      :after {
                          display: none;
                      }
                  `}
    }
`;

export const ThemeSliderStyled = styled.div`
    margin: 0 0 25px;
    padding: 45px 0;

    .paging-dot {
        fill: var(--color-white);
    }

    ${({ invert }) =>
        invert &&
        css`
            .slider-nav-btn {
                color: var(--color-secondary);
            }

            ${LeftFade} {
                background: linear-gradient(
                    -90deg,
                    rgba(255, 58, 74, 0) 0%,
                    rgba(255, 58, 74, 1) 20%
                );
            }

            ${RightFade} {
                background: linear-gradient(
                    90deg,
                    rgba(255, 58, 74, 0) 0%,
                    rgba(255, 58, 74, 1) 75%
                );
            }
        `}

    ${media.mdUp} {
        padding: 0;

        ${({ invert }) =>
            invert
                ? css`
                      margin: 0;
                  `
                : css`
                      margin: 0 0 100px;

                      .paging-dot {
                          fill: var(--color-secondary);
                      }
                  `}
    }
`;

export const Content = styled.div`
    display: flex;
    gap: 0 15px;
    align-items: baseline;
    margin: ${({ invert }) => (invert ? "0 0 25px" : "0 0 30px")};
`;

export const Title = styled.h2`
    margin: 0;

    color: var(--color-white);
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);

    ${({ invert }) =>
        !invert &&
        css`
            ${media.mdUp} {
                color: var(--color-tertiary);
            }
        `}
`;
