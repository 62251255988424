import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

export const Container = styled.div`
    position: relative;

    margin: 0 0 25px;

    ${media.mdUp} {
        margin: 35px 0 0;
    }

    ${media.xlUp} {
        max-width: ${columns(4)};
    }
`;
