import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";

import { generateQueryString } from "helpers/utils";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import QuickSearch from "components/shared/quickSearch/QuickSearch";

import { Container } from "./Finder.styled";

function Finder() {
    const { mobileView } = useMediaQueryContext();
    const router = useRouter();
    const methods = useForm({
        mode: "onSubmit"
    });

    const onSubmit = ({ q }) => {
        const queryString = generateQueryString({ q });
        router.push(`/waar-te-besteden/tickets-aan-de-kassa/?${queryString}`);
    };

    return (
        <Container>
            <FormProvider {...methods}>
                <form
                    method="GET"
                    action="/waar-te-besteden/tickets-aan-de-kassa/"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <QuickSearch
                        name="q"
                        label="Culturele instellingen in de buurt"
                        placeholder="Postcode, plaats of locatie"
                        geoLocationPosition="inline"
                        showLabel={!mobileView}
                    />
                </form>
            </FormProvider>
        </Container>
    );
}

export default Finder;
