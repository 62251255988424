import { Container, Title } from "../Theme.styled";

import { TitleSkeleton, ImageSkeleton } from "./SkeletonTheme.styled";

function SkeletonTheme() {
    return (
        <Container>
            <Title as="div">
                <TitleSkeleton />
            </Title>
            <ImageSkeleton />
        </Container>
    );
}

export default SkeletonTheme;
